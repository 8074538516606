import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {toast} from 'react-toastify'

export const getBusinessByUserId = createAsyncThunk(
  'business/getBusinessbyUserId',
  async (userId) => {
    const response = await fetch(`https://vendor.payzopartner.in/payzo-api/getBusinessesByUserId/${userId}`);
  
    if (!response.ok) {
      toast.error("No Business Found in this User Id");
      throw new Error(`HTTP error! status: ${response.status}`);
    }else{
      toast.success( " I found  some Business on " + `${userId}` + "User Id");
    }
    return response.json();
  }
);

const businessSlice = createSlice({
  name: 'business',
  initialState: {
    data: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessByUserId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload || []; // Assuming payload is an array of business data
      })
      .addCase(getBusinessByUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default businessSlice.reducer;
