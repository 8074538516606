import React, { useEffect, useState } from 'react';
import '../styles/fullscreen.scss'; // Import the necessary CSS for styling
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from './modal';

const FullScreenCard = ({ businessId }) => {
  const [isFullScreen, setIsFullScreen] = useState(false); // State to manage full-screen mode
  const [businessData, setBusinessData] = useState(null); // To store business details
  const [approvalStatus, setApprovalStatus] = useState("");
  const [remarks, setRemarks] = useState(""); // Set default remarks to empty string
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state

  // Fetch business data when component loads or businessId changes
  useEffect(() => {
    const fetchBusinessByIDData = async () => {
      try {
        const response = await axios.get(`https://vendor.payzopartner.in/payzo-api/getBusinessById/${businessId}`);
        if (response.data.success) {
          setBusinessData(response.data.business); // Set the business data
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (businessId) {
      fetchBusinessByIDData();
    }
  }, [businessId]);

  // Update the business status when approval status or remarks change
  useEffect(() => {
    if (approvalStatus && businessData) {
      const updateBusinessData = async () => {
        setIsLoading(true); // Start loading

        try {
          const updateresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/updateBusiness', {
            business_id: businessId,
            business_type: businessData.business_type,
            approval_status: approvalStatus,
            remark: remarks,
            pincode: businessData.pincode,
            user_id: businessData.user_id,
            vendor_name: businessData.vendor_name,
            business_name: businessData.business_name,
            category_id: businessData.category_id,
            city_id: businessData.city_id,
            state_id: businessData.state_id,
            address: businessData.address,
            email: businessData.email,
            google_map_link: businessData.google_map_link,
            website_link: businessData.website_link,
            store_timing: businessData.store_timing,
            profile_image: businessData.profile_image,
            phone_number: businessData.phone_number,
          });

          if (updateresponse.data.success) {
          } else {
            toast.error("Please try again later");
          }
        } catch (error) {
          console.log(error);
          toast.error("Error occurred while updating business");
        } finally {
          // Introduce a 10-second delay before stopping loader and refreshing
          setTimeout(() => {
            setIsLoading(false); // Stop loading
            // Refresh the page
          }, 3000); 
         // 10 seconds delay
        }
      };

      updateBusinessData();
    }
  }, [approvalStatus, remarks, businessData, businessId]);

  const handleEyeIconClick = () => {
    setIsFullScreen(true); // Enable full-screen view when the eye icon is clicked
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false); // Close the full-screen view
  };

  const openModal = () => {
    setIsModalOpen(true); // Open rejection modal
  };

  // Handle review submission and change approval status to rejected
  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    if (!remarks.trim()) {
      toast.error("Please provide a reason for rejection.");
      return;
    }

    setIsLoading(true); // Start loading when submitting

    try {
      // Set approval status to rejected
      setApprovalStatus("rejected");

      // Wait for a small delay for state to update
      await new Promise((resolve) => setTimeout(resolve, 200));

      // Close the modal after the review has been submitted
      setIsModalOpen(false);
      toast.success("Review Added successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error submitting review. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading after submission
    }
  };

  return (
    <div>
      {/* Eye Icon to open the full-screen card */}
      <button onClick={handleEyeIconClick} className="eye-icon">
        👁️
      </button>

      {/* Full-screen card that appears when isFullScreen is true */}
      {isFullScreen && businessData && (
        <div className="fullscreen-card">
          <button className="close-btn" onClick={handleCloseFullScreen}>X</button>
          <div className="content">
            <p><strong className='str-color'>Business Name:</strong> {businessData.business_name}</p>
            <p><strong className='str-color'>Vendor:</strong> {businessData.vendor_name}</p>
            <p><strong className='str-color'>Type:</strong> {businessData.business_type}</p>
            <p><strong className='str-color'>Category:</strong> {businessData.category_name}</p>
            <p><strong className='str-color'>City:</strong> {businessData.city_name}</p>
            <p><strong className='str-color'>State:</strong> {businessData.state_name}</p>
            <p><strong className='str-color'>Address:</strong> {businessData.address}</p>
            <p><strong className='str-color'>Email:</strong> {businessData.email}</p>
            <p><strong className='str-color'>Pincode:</strong> {businessData.pincode}</p>
            <p><strong className='str-color'>Google Map Link:</strong> 
              <a href={businessData.google_map_link} target="_blank" rel="noopener noreferrer">{businessData.google_map_link}</a>
            </p>
            <p><strong className='str-color'>Website:</strong> {businessData.website_link}</p>
            <p><strong className='str-color'>Created At:</strong> {businessData.created_at}</p>
            <p><strong className='str-color'>Approval Status:</strong> {businessData.approval_status}</p>
            <p><strong className='str-color'>Remark:</strong> {businessData.remark}</p>
            <div className='button-marker'>
              <button onClick={() => setApprovalStatus("approved")}>Approve</button>
              <button onClick={openModal} className="btn-1">Reject</button>
            </div>

            {/* Modal to submit review */}
            {isModalOpen && (
              <Modal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
                <h2>Dear Admin, please mention why you are rejecting this business</h2>
                <input
                  className="modal-input"
                  type="text"
                  placeholder="Please mention your review"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)} // Track remarks in modal input
                />
                <button onClick={handleReviewSubmit}>Submit Review</button>
              </Modal>
            )}
          </div>
        </div>
      )}

      {/* Loader - Show this when `isLoading` is true */}
      {isLoading && (
        <div className="loader">
          <div className="spinner"></div>
          <p>Updating...</p>
        </div>
      )}
    </div>
  );
};

export default FullScreenCard;
