import React, { useState, useEffect } from "react";
import "../styles/dashboard.scss";
import { IoMdNotifications } from "react-icons/io";
import { TiMessages } from "react-icons/ti";
import { FaUserCog } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Pagination from 'react-bootstrap/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getAllusersData } from "../redux/slice/user.slice";
import { getBusinessByUserId } from "../redux/slice/getbusiness.slice";
import { FilterByType } from "../redux/slice/filterbytype.slice";
import { fetchAllBusiness } from "../redux/slice/allbusiness.slice";

import { getBusinessByBusinessId as getBusinessByIdAction } from "../redux/slice/businessbyId.slice";
import { toast, ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Modal from "./modal";
import FullScreenCard from "./fullscreen";

function Dashboard() {
  const navigate = useNavigate();
  const [activeContent, setActiveContent] = useState('content1');
  const [currentPage, setCurrentPage] = useState(1);
  const [InputUserId, setInputUserId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [AllBusinessData, setAllBusinessData] = useState([]);
  const [BusinessId, setBusinessId] = useState("");
  const [BusinessByIdData, setBusinessByIdData] = useState([]);
  const [fetchedStatesData, setfetchedStatesData] = useState([]);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [cityUpload, setCityupload] = useState("");
  const [mentionBusinessType, setmentionBusinessType] = useState("")
 
  const { loading, data:userData,} = useSelector((state) => state.users);
  const { data: businessData } = useSelector((state) => state.getBusiness);
  const { BusinessTypeData, error } = useSelector((state) => state.BusinessType);
  // const BusinessIdData = useSelector((state) => state.BusinessById?.BusinessById || []);


  
  const dispatch = useDispatch();
  const usersPerPage = 10;
  const usersData = Array.isArray(userData.data) ? userData.data : [];
  const BusinessData = Array.isArray(businessData.businesses) ? businessData.businesses : [];
 const BusinessByTypeDatas = Array.isArray(BusinessTypeData.data) ? BusinessTypeData.data : [];
  // const getBusinessByBusinessId = Array.isArray(BusinessIdData?.business) ?  BusinessIdData.business : [];

  // Pagination logic
  const totalPages = Math.ceil(usersData.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = usersData.slice(indexOfFirstUser, indexOfLastUser);
  const getBusinessAll = BusinessData.slice(indexOfFirstUser, indexOfLastUser); 
  const getBusinessByBusinessType = BusinessByTypeDatas.slice(indexOfFirstUser, indexOfLastUser);
  // const getBusinessByBusinessIdData = getBusinessByBusinessId.slice(indexOfFirstUser, indexOfLastUser);
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
   
  };
  
  // Handle page click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  useEffect(()=>{
   dispatch(getAllusersData());
  }, [dispatch])

  const handlegetBusinessByBusinessType = (e) => {
    e.preventDefault();
    if (mentionBusinessType.trim()) {  // Ensure a non-empty search
      dispatch(FilterByType(mentionBusinessType));  // Dispatch filter action with the business type
    }
  };

  useEffect(()=>{
    const getAllStates = async () => {
      try {
        const StateResponse = await axios.get('https://vendor.payzopartner.in/payzo-api/getStates');
        if (StateResponse.data.success === true) {
          setState(StateResponse.data.data);  // Make sure this matches the actual data structure
        } else {
          toast.error("Failed to fetch States");
        }
      } catch (error) {
        console.log(error);
        toast.error("Error fetching states");
      }
    }
    getAllStates();
  }, [])

  useEffect(() => {
    if (selectedState) {
      axios
        .post("https://vendor.payzopartner.in/payzo-api/getCities", {
          state_id: selectedState
        })
        .then((res) => {
          console.log("API response for cities:", res);
          if (Array.isArray(res.data.data)) {
            setCities(res.data.data);
          } else {
            console.error("Expected an array but got:", res.data.data);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the cities!", error);
        });
    }
  }, [selectedState]);

  


  const generatePaginationItems = () => {
    const pages = [];

    // First page, prev, next, last
    if (currentPage > 1) {
      pages.push(<Pagination.First onClick={() => handlePageClick(1)} />);
      pages.push(<Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} />);
    }

    // Middle pagination items
    const rangeStart = Math.max(1, currentPage - 2);
    const rangeEnd = Math.min(totalPages, currentPage + 2);

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageClick(i)}>
          {i}
        </Pagination.Item>
      );
    }

    // Ellipses and last page
    if (rangeEnd < totalPages) {
      pages.push(<Pagination.Ellipsis />);
      pages.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageClick(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    // Next page, last
    if (currentPage < totalPages) {
      pages.push(<Pagination.Next onClick={() => handlePageClick(currentPage + 1)} />);
      pages.push(<Pagination.Last onClick={() => handlePageClick(totalPages)} />);
    }

    return pages;
  };

  const handleSearchBusiness = (e) => {
    e.preventDefault();
    if (InputUserId) {
      dispatch(getBusinessByUserId(InputUserId));
    } else {
      toast.error("No user Found");
    }
  };



  const fetchBusinessByBusinessId = async () => {
    try {
     
      const response = await axios.get(`https://vendor.payzopartner.in/payzo-api/getBusinessById/${BusinessId}`);
      
      if (response.data.success &&  response.data.business) {
        setBusinessByIdData([response.data.business]);
      } else {
        toast.error("Error: Invalid business data received.");
      }
      }
      
    catch (error) {
      toast.error("No Business Found")
    }
  };
  

      useEffect(()=>{
        const fetchAllBusinessData = async () => {
          try {
            const response = await axios.get('https://vendor.payzopartner.in/payzo-api/getAllBusiness');
            
            if (response.data.success && Array.isArray(response.data.businesses)) {
               
              setAllBusinessData(response.data.businesses); 
            } else {
              toast.error("Error: Invalid business data received.");
            }
          } catch (error) {
            console.error("Error fetching business data:", error);
            toast.error("Error fetching business data.");
          
      };
      }
      fetchAllBusinessData();
      },[]);



      const handleuploadCity = async(e)=> {
        e.preventDefault();
        try {
          const response = await axios.post('https://vendor.payzopartner.in/payzo-api/addCities', {
            'state_id' : selectedState,
            'name' : cityUpload
          })
          if(response.success === true){
            toast.error("Failed to upload City");
        } else {
          
          toast.success("City Uploaded Successfully ");

          }
        } catch (error) {
          console.log(error);
        }
      }


  return (
    <div>
      <div className="Dashboard-main-container">
        <ToastContainer />
        <div className="dashboard-navbar">
          <img src="https://www.payzopartner.com/static/media/logo.2e8f0a49aa17317034bd.png" alt="Logo" width={170} height={100} />
          <div style={{ width: "30rem", height: "5rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "0.2rem" }}>
            {/* <input type="search" placeholder="search username" className="universal-search" />
            <button className="search-button">Search</button> */}
            
            <marquee
            >
            <h1 style={{color:'lightblue', fontFamily:"cursive", fontWeight:'700'}}>Welcome Admin...🫡 </h1>
            </marquee>
          </div>
          <div className="icon-section">
            <p><IoMdNotifications /></p>
            <p><TiMessages /></p>
            <p><FaUserCog /></p>
            <p onClick={() => navigate('/')}><LuLogOut /></p>
            <p style={{ fontSize: '18px' }} onClick={()=> navigate('/')}>Logout</p>
          </div>
        </div>

        <div className="side-shower">
          <div className="body-content">
            <div style={{ display: 'flex' }}>
              {/* Sidebar */}
              <div style={{
                width: '200px', backgroundColor: '#f4f4f4', padding: '10px', height: '41rem', gap: '0.5rem',
                display: 'flex', flexDirection: 'column'
              }}>
                <DropdownButton id="dropdown-basic-button" title="Users">
                  <Dropdown.Item onClick={() => setActiveContent('content1')}>Search Users</Dropdown.Item>
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Business">
                  <Dropdown.Item onClick={() => setActiveContent('content3')}>Availble Business</Dropdown.Item>
                  <Dropdown.Item onClick={() => setActiveContent('content2')}>Business By User Id</Dropdown.Item>
                  <Dropdown.Item onClick={()=> setActiveContent('content5')}>Business By Business Id</Dropdown.Item>
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Filter Business">
                  <Dropdown.Item onClick={() => setActiveContent('content6')}>Filter By Business Type</Dropdown.Item>
              
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Online Offer">
                  <Dropdown.Item onClick={() => setActiveContent('content2')}>Image Offer</Dropdown.Item>
                  <Dropdown.Item onClick={() => setActiveContent('content2')}>Description Offer</Dropdown.Item>
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Offline Offer" >
                  <Dropdown.Item onClick={() => setActiveContent('content2')}>Description Offer</Dropdown.Item>
                  <Dropdown.Item onClick={() => setActiveContent('content2')}>Image Offer</Dropdown.Item>
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="State Update">
                  <Dropdown.Item onClick={() => setActiveContent('content4')}>State Update</Dropdown.Item>
              
                </DropdownButton>
              </div>

              {/* Right Content */}
              <div style={{ marginLeft: '10px', padding: '10px' }}>
                {/* Content1 */}
                {activeContent === 'content1' && (
                  <div className="content1-style">
                    <div className="content1-header">
                      <input className="user-input" type="search" placeholder="Please search by userId" />
                      <button className="content1-searcher">Search</button>
                    </div>
                    <div className="content1-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers.map((user, index) => (
                            <tr key={index}>
                              <td>{user.user_id}</td>
                              <td>{user.full_name}</td>
                              <td>{user.email}</td>
                              <td>{user.phone_number}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* Content2 */}
                {activeContent === 'content2' && (
                  <div className="content2-container">
                    <div className="content2-container-navbar">
                      <input className="search2" type="search" placeholder="Please search UserId"
                        value={InputUserId}
                        onChange={(e) => setInputUserId(e.target.value)} />
                      <button onClick={handleSearchBusiness} className="search2-button">Search User</button>
                    </div>
                    <div className="bigcard-box">
                      {getBusinessAll.map((items, index) => (
                        <div key={index} className="sideuser-content">
                          <img className="mega-pixel" src={items.profile_image} alt="" />
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Business Name :-</strong>
                            <small>{items.business_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Vendor Name :-</strong>
                            <small>{items.vendor_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Email :-</strong>
                            <small>{items.email}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Phone Number :-</strong>
                            <small>{items.phone_number}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Store Category :-</strong>
                            <small>{items.category_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>City :-</strong>
                            <small>{items.city_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Address :-</strong>
                            <small style={{ fontSize: '11px' }}>{items.address}</small>
                          </div>
                          <div className="aproval">
                            <button className="btn-1">Approval</button>
                            <button onClick={openModal} className="btn-1">Reject</button>


                       <Modal isOpen={isModalOpen} closeModal={closeModal}>
                         <h2>Dear Admin please mention Why you Reject Business</h2>
                          <input className="modal-input" type="text" placeholder="Please mention your Review" />
                           <button onClick={closeModal}>Submit Review</button>
                             </Modal>
                            
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Content 3 */}
                {activeContent === 'content3' && (
                   <div className="content3-style">
                   <div className="main-content3">
                     {loading ? (
                       <p>Loading...</p>
                     ) : (
                       <div className="table-responsive" style={{ maxHeight: '700px', overflowx: 'auto' }}>
                         <table className="table table-striped table-hover">
                           <thead>
                             <tr>
                               <th >Business Id</th>
                               <th>User Id</th>
                               <th>Business Name</th>
                               <th>Business Type</th>
                               <th>Category Name</th>
                               <th>City Name</th>
                               <th>State Name</th>
                               <th>Address</th>
                               <th>Email</th>
                               <th>Pincode</th>
                               <th>Google Map Link</th>
                               <th>Website Link</th>
                               <th>Created at</th>
                               <th>Status</th>
                               <th>Remark</th>
                               <th>Full screen</th>
                             </tr>
                           </thead>
                           <tbody>
                             {AllBusinessData.length > 0 ? (
                               AllBusinessData.map((item, index) => (
                                 <tr key={index}>
                                   <td>{item.business_id}</td>
                                   <td>{item.user_id}</td>
                                   <td>{item.business_name}</td>
                                   <td>{item.business_type}</td>
                                   <td>{item.category_name}</td>
                                   <td>{item.city_name}</td>
                                   <td>{item.state_name}</td>
                                   <td>{item.address}</td>
                                   <td>{item.email}</td>
                                   <td>{item.pincode}</td>
                                   <td>{item.google_map_link}</td>
                                   <td>{item.website_link}</td>
                                   <td>{item.created_at}</td>
                                   <td>{item.approval_status}</td>
                                   <td>{item.remark}</td>
                                   <td ><FullScreenCard businessId={item.business_id}/></td>
                                 </tr>
                               ))
                             ) : (
                               <tr>
                                 <td colSpan="12" style={{ textAlign: 'center' }}>No business data available</td>
                               </tr>
                             )}
                           </tbody>
                         </table>
                       </div>
                     )}
                   </div>
                 </div>
                     
                    )}


               {/* Content 4 - Business Approval */}
{activeContent === 'content4' && (
  <div className="content4-style">
    <div className="content4-navbar">
      <h1 className="state-title">State and Cities Update</h1>
    </div>

    {/* Map over fetched states and create a dropdown for each */}
    
      <div className="user-details-container" >
        
         <select
         value={selectedState}
         onChange={(e) => setSelectedState(e.target.value)}
         className="input-businesss"
       >
         <option value="" >
           Select a state
         </option>
         {state.map((item, index) => (
           <option key={index} value={item.id}>
             {item.name}
           </option>
         ))}
       </select>
       <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', gap:'3rem'}}>
         <input type="search" value={cityUpload} onChange={(e)=> setCityupload(e.target.value)} placeholder="please enter City Name" />
       <button onClick={handleuploadCity}>Upload City</button>
        </div>
          </div>
            </div>

            

               )}




                {/* content 5 */}
{activeContent === 'content5' && (
  <div className="content5-container">
    <div className="content5-navbar">
      <button>Approve</button>
      <button onClick={openModal}>Reject</button>
      
      {/* Modal component */}
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <h2>Dear Admin, please mention why you reject the business</h2>
        <input className="modal-input" type="text" placeholder="Please mention your review" />
        <button onClick={closeModal}>Submit Review</button>
      </Modal>
    </div>

    {/* Body Section */}
    <div className="content5-bodysection">
      {/* Search Section */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
        <input type="text" value={BusinessId}
         onChange={(e) => setBusinessId(e.target.value)}
        className="content5-input" placeholder="Please search Business Id" />
        <button onClick={fetchBusinessByBusinessId}>Search</button>
      </div>

      {/* Rendering business data */}
      {BusinessByIdData.map((item, index) => {
        return (
          <div className="content5-card" key={index}>
            <div className="businessone">
              <img
                className="content5-sideimage"
                src={item.profile_image}
                alt="business"
              />
              <h6 style={{color:"red"}}>Business Name : {item.business_name}</h6>
              <h6>Vendor Name : {item.vendor_name}</h6>
              <h6>Business Type : {item.business_type}</h6>
            </div>
            <div className="businesstwo">
              <h6>Store timing : {item.store_timing}</h6>
              <h6>Phone Number : 6281073571</h6>
                <h6>Address</h6>
              <marquee>
                <h6>{item.address}</h6>
              </marquee>
              <h6>State :{item.state_name}</h6>
              <h6>City : {item.city_name}</h6>
              <h6>Email : {item.email}</h6>
              <h6>Pincode : {item.pincode}</h6>
              <a href={item.google_map_link}>Google Map Link :  {item.google_map_link}</a>
              <a href={item.website_link}>Website Link : {item.website_link}  notprovided</a>
              <h6>Approval status : {item.approval_status}</h6>
              <h5>Remark : {item.remark}</h5>
            </div>
          </div>
        );
      })}
    </div>
  </div>
)}




      {/* content 6 */}
      {activeContent === 'content6' && (
        <div className="content6-container">
          <div className="content6-navbar">
            <h2>FILTERED BY BUSINESS TYPE</h2>
            <input
              type="text"
              className="type-searcher-input"
              placeholder="Enter Business Type"
              value={mentionBusinessType}  // Bind input value to state
              onChange={(e)=> setmentionBusinessType(e.target.value)} // Update state on input change
            />
            <button onClick={handlegetBusinessByBusinessType}>Search Business Type</button>
          </div>
          <div className="content6-card-container">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
               BusinessTypeData?.data?.map((item, index) => (
                <div className="filter-card" key={index}>
                  <img className="filtercard-image" src={item.profile_image || ""} alt={item.business_name} />
                  <h4>{item.business_name}</h4>
                  <h6>Business Type : {item.business_type}</h6>
                  <h6>Phone Number : {item.phone_number}</h6>
                  <h6>Approval Status : {item.approval_status}</h6>
                </div>
              ))
            )}
          </div>
        </div>
      )}
  
 
                            

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="parent-pagination">
        <Pagination>{generatePaginationItems()}</Pagination>
      </div>
    </div>
  );
}

export default Dashboard;


