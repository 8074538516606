import { configureStore } from "@reduxjs/toolkit";
import userSlicereducer from "../slice/user.slice.js";
import Businessreducer from "./getbusiness.slice.js";
import AnybusinessReducer from "./allbusiness.slice.js";
import AdminLoginReducer from "./adminlogin.slice.js";
import AuthReducer from "./auth.slice.js";
import BusinessByIdreducer from "./businessbyId.slice.js";
import FilterByBusinessTypeReducer from "./filterbytype.slice.js";

const store = configureStore({
    reducer : {
      users : userSlicereducer,
      getBusiness : Businessreducer,
      anyBusiness : AnybusinessReducer,
      admin : AdminLoginReducer,
      BusinessById: BusinessByIdreducer,
      BusinessType : FilterByBusinessTypeReducer,
      // auth : AuthReducer,
    },
});


export default store;