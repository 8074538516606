import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Define the async thunk to fetch business data by ID
export const getBusinessByBusinessId = createAsyncThunk(
  'Business/getBusinessByBusinessId', 
  async (BusinessId) => {
      const response = await fetch(`https://vendor.payzopartner.in/payzo-api/getBusinessById/${BusinessId}`, {
          method : 'GET',
          headers : {
              'content-type' : 'application/json',
          }
      });
      
      if (!response.ok) {
          toast.error("Failed to fetch Business ID");
          throw new Error("Error fetching Business Data");
      } else {
          toast.success("Business found successfully");
      }
      
      return response.json();
  }
);

const BusinessByIdSlice = createSlice({
  name: 'BusinessById',
  initialState: {
      BusinessIdData: [],
      loading: false,
      error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
      builder
          .addCase(getBusinessByBusinessId.pending, (state) => {
              state.loading = true;
          })
          .addCase(getBusinessByBusinessId.fulfilled, (state, action) => {
              state.loading = false;
              state.BusinessIdData = action.payload || [];
          })
          .addCase(getBusinessByBusinessId.rejected, (state, action) => {
              state.loading = false;
              state.error = action.error.message;
          });
  },
});

export default BusinessByIdSlice.reducer;
