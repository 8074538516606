import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginScreen from './components/login';
import Dashboard from './components/dashboard';
import PrivateRoute from './components/privateroute';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* Public Route */}
          <Route path="/" element={<LoginScreen />} />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Private Route (Protected) */}
          <Route element={<PrivateRoute />}>
            
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
