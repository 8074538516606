import React, { useState, useEffect } from 'react';
import "../styles/login.scss";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AdminLogin } from '../redux/slice/adminlogin.slice';
import {toast, ToastContainer} from "react-toastify";

function LoginScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const data = useSelector((state) => state.admin.data);

    const AdminLoginButton = () => {
  
        // dispatch(AdminLogin({ phoneNumber, password }));
      if(phoneNumber === "" && password === ""){
        toast.error("Please fill All fields")
      }else if(phoneNumber === "6281073571" && password === "Deleep@123"){
          toast.success("Login Successfuly");
        setTimeout(()=>{
            navigate('/dashboard');
        }, 2000)
      }
    };


    // useEffect(() => {
    //     if (data && data.token) {
    //         navigate('/dashboard'); 
    //     }
    // }, [data, navigate]);

    return (
        <div>
            <ToastContainer/>
            <div className='login-main-container'>
                <div className='card-container'>
                    <div className='origin'>
                        <img
                            className='side-image'
                            src='https://static.vecteezy.com/system/resources/previews/037/249/614/non_2x/overload-icon-vector.jpg'
                            alt=''
                        />
                    </div>

                    <div className='side-content'>
                        <img
                            src='https://www.payzopartner.com/static/media/logo.2e8f0a49aa17317034bd.png'
                            alt=''
                            width={200}
                            height={100}
                        />
                        <h1>Vendor Administration</h1>
                        <input
                            className='admin-input'
                            type='text'
                            placeholder='Enter Email / Mobile Number'
                            value={phoneNumber}
                            maxLength={10}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <input
                            className='admin-input'
                            type='password'
                            placeholder='Enter Password'
                            maxLength={12}
                            minLength={8}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className='admin-button' onClick={AdminLoginButton}>
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginScreen;
