import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

export const FilterByType = createAsyncThunk(
  'filter/filterByType',
  async (mentionBusinessType) => {
    try {
      const response = await fetch('https://vendor.payzopartner.in/payzo-api/filterByBusinessType', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          business_type: mentionBusinessType,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          toast.success("Business fetched Successfully");
        } else {
          toast.error("Failed to fetch Business");
        }
        return data; // Return the JSON data
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
      throw error; // Throw error to be caught in the rejected case
    }
  }
);

const BusinessTypeSlice = createSlice({
  name: 'BusinessType',
  initialState: {
    BusinessTypeData: [],  // Corrected this to match initial state
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FilterByType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(FilterByType.fulfilled, (state, action) => {
        state.loading = false;
        state.BusinessTypeData = action.payload; // Corrected the state update
      })
      .addCase(FilterByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;  // Handle error from rejected action
      });
  }
});

export default BusinessTypeSlice.reducer;
