import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


export const AdminLogin = createAsyncThunk(
    'admin/login',
    async (username, password)=> {
        const response  = await ('https://newadmin.payzopartner.com/payzo-api/login', {
            method : 'POST',
            headers : {
                'content-Type' : 'application/json',
            },
            body: {
                'username' : username,
                'password' : password
            }
            
        })
        if(!response.ok){
            toast.error("Invalid Username or Password");
            throw new Error("Http error");
        }else{
            toast.success("Login Successful");
        }
    }
)


const adminLoginSlice = createSlice({
    name:'admin',
    initialState: {},
    reducers: {},
    extraReducers : (builder) => {
        builder 
        .addCase(AdminLogin.pending, (state)=>{
             state.loading = true;
        })
        .addCase(AdminLogin.fulfilled, (state, action)=>{
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(AdminLogin.rejected, (state, error)=>{
            state.loading = false;
            state.error = error.message;
        })
    }
}
    
);

export default adminLoginSlice.reducer;