import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

export const fetchAllBusiness = createAsyncThunk(
    'fetchAllBusiness',
    async()=> {
        const response  = await fetch('https://vendor.payzopartner.in/payzo-api/getAllBusiness')
        if(!response.ok){
            throw new Error("Error fetching Business");
        }else{
            toast.success("All Business fetched Successfully");
        }
    }
);

const getAllBusinessSlice = createSlice({
    name:'anyBusiness',
    initialState:{
        AllBusinessData : [],
        loading: false,
        error : null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder 
        .addCase(fetchAllBusiness.pending, (state)=>{
            state.loading = true;
        })
        .addCase(fetchAllBusiness.fulfilled, (state, action)=>{
            state.loading = false;
            state.AllBusinessData = action.payload || [];

        })
        .addCase(fetchAllBusiness.rejected, (state, action)=>{
            state.loading = false;
            state.error = action.error.message;
        });
    }
});

export default getAllBusinessSlice.reducer;