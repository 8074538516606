import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Fetch users from API
export const getAllusersData = createAsyncThunk(
  'users/getAllusersData',
  async () => {
    const response = await fetch('https://api.payzopartner.com/payzo-api/users', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
   
      throw new Error('Error fetching Users');
    }

    return response.json();
  }
);

// Users slice
const Usersslice = createSlice({
  name: 'users',
  initialState: {
    loading: false,
    data: [],  // Initialize data as an empty array
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    // Inside the slice reducer, add logs to track state updates
.addCase(getAllusersData.pending, (state) => {
    state.loading = true;

  })
  .addCase(getAllusersData.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload;
    console.log('Fetched users:', action.payload);
  })
  .addCase(getAllusersData.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    console.log('Error fetching users:', action.error.message);
  })
  
  },
});

export default Usersslice.reducer;
